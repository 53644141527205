<template>
  <div class="page-accounting">
    <accounting-navigation />
    <div class="agGrid-page container">
      <base-header>
        <show-error-invoices-addon />
      </base-header>
      <manual-invoice-component />
      <base-ag-grid
        :columnDefs="columnDefs"
        :datasource="datasource"
        :cellValueChanged="cellValueChanged"
        :masterDetail="true"
        :detailCellRendererParams="detailCellRendererParams"
      />
    </div>
  </div>
</template>

<script>
// Components
import BaseAgGrid from '@/agGridV2/components/base.component'
import AccountingNavigation from '@/components/AccountingNavigation'
import ManualInvoiceComponent from '@/agGridV2/components/manual-invoice.component'
// API Init
import MtApi from '@/agGridV2/helpers/mt-api.helper'
import SimpleHelper from '@/agGridV2/helpers/simple.helper'
// Custom Cell Renderer
import CheckboxRenderer from '@/agGridV2/renderers/checkbox.renderer'
import DatePickRenderer from '@/agGridV2/renderers/date-pick.renderer'
import InvoicesProjectsSelectRenderer from '@/agGridV2/renderers/invoices-projects-select.renderer'
import InvoicesCustomGroupRenderer from '@/agGridV2/renderers/invoices-custom-group.renderer'
import ShowErrorInvoicesAddon from '@/agGridV2/components/header/show-error-invoices.component.vue'
import BaseHeader from '@/agGridV2/components/header/header.component.vue'
import LinePopup from '@/agGridV2/components/line-popup.component.vue'

export default {
  components: {
    BaseHeader, ShowErrorInvoicesAddon,
    BaseAgGrid,
    AccountingNavigation,
    // eslint-disable-next-line vue/no-unused-components
    CheckboxRenderer,
    // eslint-disable-next-line vue/no-unused-components
    DatePickRenderer,
    // eslint-disable-next-line vue/no-unused-components
    InvoicesProjectsSelectRenderer,
    // eslint-disable-next-line vue/no-unused-components
    InvoicesCustomGroupRenderer,
    ManualInvoiceComponent
  },
  data() {
    return {
      datasource: null,
      columnDefs: null,
      cellValueChanged: null,
      detailCellRendererParams: null
    }
  },
  async mounted() {
    this.columnDefs = [
      {
        field: 'quickbook_id',
        headerName: 'ID',
        cellRenderer: 'InvoicesCustomGroupRenderer',
        filter: 'agNumberColumnFilter'
      },
      {
        field: 'client_name',
        headerName: SimpleHelper.columnLabels.CLIENT_NAME
      },
      {
        field: 'projects',
        headerName: 'Projects',
        cellRenderer: function (params) {
          if (!params.value) {
            return ''
          }
          let stringProjects = '<div class="invoices-projects">'
          for (const project of params.value) {
            let intClass = ''
            if (project.type.includes('-INT')) {
              intClass = 'project_int'
            }
            stringProjects += ` <div class="project_status ${intClass} project_status--${project.status}">${project.abbr_l}-${project.type}</div>`
          }
          stringProjects += '</div>'
          return stringProjects
        }
      },
      {
        field: 'project',
        headerName: 'Project',
        cellRenderer: 'InvoicesProjectsSelectRenderer',
        cellStyle: { overflow: 'visible' },
        editable: true,
        suppressFillHandle: false,
        width: 300,
        filter: false
      },
      {
        field: 'upsell',
        headerName: 'Upsell',
        cellRenderer: 'CheckboxRenderer',
        cellRendererParams: {
          disabledEnabled: true
        },
        filter: false
      },
      {
        field: 'amount',
        headerName: 'Amount',
        valueFormatter: (params) => {
          return SimpleHelper.priceFormatter(params.value)
        },
        filter: 'agNumberColumnFilter'
      },
      {
        field: 'quickbook_note',
        headerName: 'QB Note',
        cellEditor: 'agLargeTextCellEditor',
        cellEditorPopup: true,
        cellEditorParams: {
          maxLength: 100,
          rows: 10,
          cols: 50
        }
      },
      {
        field: 'custom_note',
        headerName: 'Note',
        editable: true,
        cellEditor: 'agLargeTextCellEditor',
        cellEditorPopup: true,
        cellEditorParams: {
          maxLength: 100,
          rows: 10,
          cols: 50
        }
      },
      {
        field: 'status',
        headerName: 'Status'
      },
      {
        field: 'created_date',
        headerName: 'Сreation Date',
        valueFormatter: (params) => {
          return SimpleHelper.dateFormatter(params.value)
        },
        filter: 'agDateColumnFilter'
      },
      {
        field: 'payment_date',
        headerName: 'Payment/Refund Date',
        valueFormatter: (params) => {
          return SimpleHelper.dateFormatter(params.value)
        },
        filter: 'agDateColumnFilter'
      },
      {
        field: 'applied_date',
        headerName: 'Applied date',
        cellRenderer: 'DatePickRenderer',
        cellStyle: { overflow: 'visible', padding: 0, margin: 0, border: 0 },
        filter: false
      },
      {
        field: 'canceled',
        headerName: 'Refunded/Canceled',
        cellRenderer: 'CheckboxRenderer',
        cellRendererParams: {
          disabledEnabled: true
        },
        filter: false
      },
      {
        field: 'sub_invoices',
        hide: true
      },
      {
        field: '_qb_line_string',
        cellRenderer: (params) => {
          const btn = document.createElement('button')
          btn.textContent = 'Show Json'
          btn.className = 'button-outline-blue'
          btn.addEventListener('click', () => {
            this.$modal.show(
              LinePopup,
              {
                jsonString: params.data._qb_line_string
              }, { width: '70%' }
            )
          })
          return btn
        },
        editable: false
      }
    ]
    this.datasource = {
      getRows: (params) => {
        params.request['search'] = this.$store.getters.getSearchQuery(this.$route.name)
        params.request['showErrorInvoices'] = this.$store.getters.getShowErrorInvoices(this.$route.name)
        MtApi.agGridInvoices(params.request).then((response) => {
          params.success({ rowData: response.rows, rowCount: response.count })
        })
      }
    }
    this.detailCellRendererParams = (masterGridParams) => ({
      detailGridOptions: {
        context: {
          masterGrid: masterGridParams
        },
        headerHeight: 32,
        enableRangeSelection: true,
        enableFillHandle: true,
        suppressRowTransform: true,
        rowHeight: 32,
        columnDefs: [
          {
            headerName: 'Actions',
            cellRenderer: 'InvoicesCustomGroupRenderer',
            suppressFillHandle: true
          },
          {
            field: 'project',
            headerName: 'Project',
            cellRenderer: 'InvoicesProjectsSelectRenderer',
            cellStyle: { overflow: 'visible' },
            editable: true,
            suppressFillHandle: false,
            width: 300,
            filter: false
          },
          {
            field: 'upsell',
            headerName: 'Upsell',
            cellRenderer: 'CheckboxRenderer',
            cellRendererParams: {
              disabledEnabled: true
            },
            filter: false
          },
          {
            field: 'amount',
            headerName: 'Amount',
            editable: true,
            valueFormatter: (params) => {
              return SimpleHelper.priceFormatter(params.value)
            }
          },
          {
            field: 'custom_note',
            headerName: 'Note',
            editable: true,
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
              maxLength: 100,
              rows: 10,
              cols: 50
            }
          },
          {
            headerName: 'Applied date',
            field: 'applied_date',
            cellRenderer: 'DatePickRenderer',
            cellStyle: { overflow: 'visible', padding: 0, margin: 0, border: 0 }
          },
          {
            field: 'canceled',
            headerName: 'Refunded/Canceled',
            cellRenderer: 'CheckboxRenderer'
          }
        ],
        async onCellValueChanged(params) {
          const parent = params?.context?.masterGrid
          const currentSubInvoices = parent.node.data.sub_invoices
          currentSubInvoices[params.node.rowIndex] = params.data
          const updatedSubInvoices = [...currentSubInvoices]
          parent.node.setDataValue('sub_invoices', updatedSubInvoices)
          parent.api.redrawRows()
        }
      },
      // get the rows for each Detail Grid
      getDetailRowData: (params) => {
        const sub_invoices = params.data.sub_invoices
        params.successCallback(
          sub_invoices.map((s) => {
            return {
              ...s,
              projects: params.data.projects
            }
          })
        )
      }
    })
    this.cellValueChanged = (params) => {
      const context = params.context
      let data = {}
      if (context && context?.event === 'repeat_sub_invoices') {
        data = {
          event: 'repeat_sub_invoices',
          field: {
            invoice_id: params.data._id,
            name: 'sub_invoices',
            value: params.value
          }
        }
      } else {
        data = {
          event: 'update_invoice',
          field: {
            invoice_id: params.data._id,
            name: params.colDef.field,
            value: params.value
          }
        }
      }
      params.api.redrawRows()
      MtApi.updateClient(params.data.client_id, data)
      params.api.setGridOption('context', {
        event: ''
      })
    }
  }
}
</script>
