<template>
  <div class="ag-grid-header">
    <div class="activities">
      <search-addon />
    </div>
    <div class="actions">
      <div class="slots">
        <slot></slot>
      </div>
      <div class="cross">
        <button id="reset-state-button" :class="['button-outline-blue', resetStateDisable]" @click="resetState">Reset State</button>
        <button id="reset-filters-button" :class="['button-outline-blue', resetFilterDisable]" @click="resetFilters">Reset Filters</button>
        <div class="fullScreen" @click="fullscreen"><div></div><div></div></div>
      </div>
    </div>
  </div>
</template>
<script>
import SearchAddon from '@/agGridV2/components/header/search.component.vue'

export default {
  name: 'base-header',
  components: {
    SearchAddon
  },
  data() {
    return {
      state: null,
      stateFilter: null
    }
  },
  mounted() {
    this.fullscreen(null, true)
  },
  computed: {
    resetFilterDisable() {
      let status = 'disabled'
      if (this.$store.getters.getAgGridFilterChanged(this.$route.name)) {
        status = ''
      }
      if (this.$store.getters.getSearchQuery(this.$route.name)) {
        status = ''
      }
      // if (!this.$store.getters.getShowArchived(this.$route.name)) {
      //   status = ''
      // }
      if (this.$store.getters.getShowErrorInvoices(this.$route.name)) {
        status = ''
      }
      return status
    },
    resetStateDisable() {
      let status = 'disabled'
      if (this.$store.getters.getAgGridState(this.$route.name)) {
        status = ''
      }
      return status
    }
  },
  methods: {
    fullscreen(event, init = false) {
      const toggleFullScreen = () => {
        this.$store.dispatch('toggleFullScreen', { page: this.$route.name })
        this.updateFullScreenClass()
      }

      const keyClose = () => {
        document.addEventListener('keydown', event => {
          if (event.code === 'Escape') {
            const isModal = document.querySelector('.vm--modal');
            if (!isModal) {
              toggleFullScreen()
            }
          }
        }, { once: true });
      }

      if (init) {
        const isFullScreen = this.$store.getters.isFullScreen(this.$route.name)
        if (isFullScreen) {
          this.updateFullScreenClass()
          keyClose()
        }
      } else {
        toggleFullScreen()
        keyClose()
      }
    },
    updateFullScreenClass() {
      const elements = document.getElementsByClassName('agGrid-page')
      const isFullScreen = this.$store.getters.isFullScreen(this.$route.name)
      Array.from(elements).forEach((element) => {
        element.classList.toggle('ag-fullScreen', isFullScreen)
      })
    },
    resetState() {
      this.$emitter.emit('header_reset_state', {})
    },
    resetFilters() {
      this.$emitter.emit('header_reset_filters', {})
    }
  }
}
</script>
