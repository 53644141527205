export default {
  columnLabels: {
    CLIENT_NAME: 'Client Name (QB)',
    TOTAL_FROM_2021: 'Total Revenue (from January 1, 2021)',
    BUDGET_FROM_2021: 'Total Budget (from January 1, 2021)'
  },
  monthsLabels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  priceFormatter(price, allowNull = false) {
    const num = Number(price)
    if (isNaN(num) || num === 0) {
      if (allowNull) {
        return '$0'
      }
      return ''
    }
    return '$' + num.toLocaleString('en', { maximumFractionDigits: 2 })
  },
  percentFormatter(percent, allowNull = false) {
    if (!percent) {
      return percent
    }
    return `${percent}%`
  },
  dateFormatter(date, time = false) {
    if (!date) return '--/--'
    const originDate = new Date(date)
    let formatter = new Intl.DateTimeFormat('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
    if (time) {
      formatter = new Intl.DateTimeFormat('en-US', { month: '2-digit', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' })
    }
    return formatter.format(originDate)
  },
  monthYearStringToLabels(string) {
    const [month, year] = string.split('_')
    const monthIndex = parseInt(month, 10) - 1
    return `${this.monthsLabels[monthIndex]} ${year}`
  },
  dayMonthYearStringToLabels(string) {
    const [day, month, year] = string.split('_')
    const monthIndex = parseInt(month, 10) - 1
    return `${day} ${this.monthsLabels[monthIndex]} ${year}`
  },
  getModifiedFiltersLabels(filters) {
    return filters.map((filter) => {
      // return filter.type ? `${filter.type} (${filter.count})` : `Blank (${filter.count})`
      return filter.type
    })
  }
}
