<template>
  <div class="ag-grid-container">
    <div class="ag-table">
      <ag-grid-vue
        v-if="datasource"
        class="ag-theme-alpine"
        style="width: 100%; height: 100%"
        headerHeight="32"
        rowHeight="32"
        :columnDefs="columnDefs"
        :masterDetail="masterDetail"
        :detailCellRendererParams="detailCellRendererParams"
        :detailRowAutoHeight="true"
        embedFullWidthRows="true"
        :defaultColDef="defaultColDef"
        :rowClassRules="rowClassRules"
        :getRowId="getRowId"
        tooltipShowDelay="0"
        rowModelType="serverSide"
        cacheBlockSize="90"
        :pinnedTopRowData="pinnedTopRowData"
        groupDisplayType="singleColumn"
        :statusBar="statusBar"
        :rowSelection="rowSelection"
        :enableRangeSelection="enableRangeSelection"
        :undoRedoCellEditing="true"
        :undoRedoCellEditingLimit="20"
        :getContextMenuItems="getContextMenuItems"
        @grid-ready="onGridReady"
        @selection-changed="handleSelectionChanged"
        @cell-value-changed="handleCellValueChanged"
        @column-resized="stateEvents"
        @sort-changed="stateEvents"
        @column-visible="stateEvents"
        @column-pivot-changed="stateEvents"
        @column-row-group-changed="stateEvents"
        @column-value-changed="stateEvents"
        @column-moved="stateEvents"
        @column-pinned="stateEvents"
        @filter-changed="stateEvents"
      >
      </ag-grid-vue>
    </div>
  </div>
</template>
<script>
// Grid Init
import 'ag-grid-enterprise/styles/ag-grid.css'
import 'ag-grid-enterprise/styles/ag-theme-alpine.css'
import { AgGridVue } from 'ag-grid-vue'
import { mapMutations } from 'vuex'

export default {
  name: 'base-ag-grid',
  data() {
    return {
      gridApi: null,
      gridColumnApi: null,
      pinnedTopRowData: [],
      defaultColDef: {
        sortable: true,
        resizable: true,
        width: 200,
        unSortIcon: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          maxNumConditions: 1,
          buttons: ['reset']
        }
      },
      rowClassRules: {
        'row-transparent-outer': (params) => {
          if (!params.data) return false
          return (
            params.data.old || params.data.status === 'archived' || params.data.status === 'imported' || (params.data.status === 'calc' && params.data.label_strong)
          )
        },
        'row-auto-data': (params) => {
          if (!params.data) return false
          return (
            params.data.status === 'auto' || (params.data.status === 'calc' && !params.data.label_strong)
          )
        }
      },
      addons: null
    }
  },
  props: {
    datasource: null,
    columnDefs: null,
    cellValueChanged: null,
    pinnedTopRowDataCondition: null,
    customRowId: null,
    detailCellRendererParams: null,
    masterDetail: null,
    customHeaderComponent: Object,
    onSelectionChanged: null,
    statusBar: null,
    rowSelection: null,
    enableRangeSelection: null,
    getContextMenuItems: null
  },
  components: {
    AgGridVue
  },
  computed: {
    getRowId() {
      if (this.customRowId) {
        return this.customRowId
      }
      return (params) => {
        return params.data._id
      }
    }
  },
  watch: {
    pinnedTopRowDataCondition(newVal) {
      let pinnedData = newVal()
      this.pinnedTopRowData = pinnedData.result
    }
  },
  mounted() {
    this.setViewLoader(true)
    this.$emitter.on('header_addons_changed', () => {
      if (this.gridApi && this.gridApi.destroyCalled === false) {
        this.gridApi.refreshServerSide({ purge: true })
      }
    })
    this.$emitter.on('header_reset_state', () => {
      this.gridApi.resetColumnState()
      const timeout = setTimeout(() => {
        this.$store.dispatch('setAgGridState', { page: this.$route.name, query: '' })
        clearTimeout(timeout)
      }, 10)
    })
    this.$emitter.on('header_reset_filters', () => {
      if (this.gridApi && this.gridApi.destroyCalled === false) {
        this.gridApi.setFilterModel(null)
        const timeout = setTimeout(() => {
          this.$store.dispatch('setAgGridFilterChanged', { page: this.$route.name, query: '' })
          clearTimeout(timeout)
        }, 10)
        this.gridApi.refreshServerSide({ purge: true })
      }
    })
    this.$emitter.on('ag_grid_update_row', (object) => {
      const rowNode = this.gridApi.getRowNode(object._id)
      if (rowNode) {
        rowNode.updateData(object.data)
        rowNode.setSelected(false)
      }
    })
  },
  beforeDestroy() {
    this.$emitter.off('header_addons_changed')
    this.$emitter.off('header_reset_state')
    this.$emitter.off('header_reset_filters')
    this.$emitter.off('grid_api')
  },
  methods: {
    ...mapMutations(['setViewLoader']),
    // Main Functions
    async onGridReady(params) {
      this.gridApi = params.api
      this.gridColumnApi = params.columnApi
      this.setState()
      this.$emitter.emit('grid_api', params.api)
      params.api.setGridOption('serverSideDatasource', this.datasource)
      this.setViewLoader(false)
    },
    stateEvents(event) {
      if (event.type === 'filterChanged') {
        this.$store.dispatch('setAgGridFilterChanged', { page: this.$route.name, query: JSON.stringify(this.gridApi.getFilterModel()) })
      } else {
        this.$store.dispatch('setAgGridState', { page: this.$route.name, query: JSON.stringify(this.gridApi.getColumnState()) })
      }
    },
    setState() {
      const state = this.$store.getters.getAgGridState(this.$route.name)
      if (state) {
        this.gridApi.applyColumnState({ state: JSON.parse(state), applyOrder: true })
      }
      const stateFilter = this.$store.getters.getAgGridFilterChanged(this.$route.name)
      if (stateFilter) {
        this.gridApi.setFilterModel(JSON.parse(stateFilter))
      }
    },
    // Helpers Functions
    handleCellValueChanged(event) {
      if (typeof this.cellValueChanged === 'function') {
        this.cellValueChanged(event)
      } else {
        this.dummyObject()
      }
    },
    handleSelectionChanged(event) {
      if (typeof this.onSelectionChanged === 'function') {
        this.onSelectionChanged(event)
      } else {
        this.dummyObject()
      }
    },
    dummyObject() {},
    onCellKeyPress(e) {
      console.log(e.event.key)
    }
  }
}
</script>
